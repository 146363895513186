<template>
  <div class="major-player-page">
    <gaudy-title label="课程学习">
      <div class="back-btn" @click="handlerBack">
        <svg-icon icon="icon-fanhui" />
        <span>返回上一页</span>
      </div>
    </gaudy-title>
    <div class="major-player-body">
      <div class="major-player-left">
        <div class="major-player-header">
          <div class="major-player-title">
            <div class="title-name">{{ playerInfo.courseName }}</div>
            <div class="title-content">
              <div class="content-item">
                <svg-icon icon="icon-jiangshi" class-name="svgIcon" />
                <span>{{ playerInfo.unitSpeaker }}</span>
              </div>
              <div class="content-item">
                <svg-icon icon="icon-keshi" class-name="svgIcon" />
                <span>{{ playerInfo.credits }}学时</span>
              </div>
            </div>
          </div>
          <div
            class="major-player-btn"
            v-if="playerInfo.PDFurl.length > 0"
            @click="handlerFileDown"
          >
            <div class="file-down">
              <svg-icon icon="icon-fujian" />
              <span>文字版课件下载</span>
            </div>
          </div>
        </div>
        <div class="major-player-video">
          <video-player
            v-if="playerList.length > 0"
            ref="videoPlayer"
            :focus="false"
            :ifTabFocus="true"
            :ifBrush="true"
            :if-drag-seek="dragSeek"
            :brushTime="1800"
            @play="playerStartInit"
            @ended="playEnded"
            @switch="playerSwitch"
            @timeupdate="videoPlayerTimeUpDate"
            :playerList="playerList"
            :currentUrl="playerIndex"
          />
        </div>
        <div class="major-player-bottom">
          <div class="major-player-bottom-left">
            <div class="bottom-label">您对课程的满意度如何？</div>
            <el-rate show-text v-model="formData.star" />
          </div>
          <div class="major-player-bottom-content">
            <el-input
              placeholder="请输入您的评价"
              v-model="formData.evaluationContent"
            >
              <span slot="append" @click="submitEvaluation">提交评价</span>
            </el-input>
          </div>
        </div>
      </div>
      <div class="major-player-right">
        <div class="major-player-progress">
          <div class="major-player-header">
            <div class="major-player-title">当前学习进度</div>
            <div class="major-player-number">
              <span>{{ rateOfLearning }}</span
              >/{{ playerList.length }}
            </div>
          </div>
          <div class="major-player-progress-body">
            <el-progress
              v-if="
                !isNaN(parseInt((rateOfLearning / playerList.length) * 100))
              "
              :percentage="parseInt((rateOfLearning / playerList.length) * 100)"
              status="exception"
            />
          </div>
        </div>
        <div class="major-player-catalogue">
          <div class="catalogue-title">
            <span class="name">课程目录</span>
            <span class="number"
              >({{ playerIndex + 1 }}/{{ playerList.length }})</span
            >
          </div>
          <el-scrollbar class="catalogue-body">
            <div
              class="catalogue-item"
              @click="catalogueClick(item, index)"
              :class="{ active: playerIndex === index }"
              v-for="(item, index) in playerList"
              :key="index"
            >
              <div class="catalogue-item-left">
                <div class="item-chapter">第{{ index + 1 }}节</div>
                <div class="item-name">{{ item.courseContName }}</div>
              </div>
              <div class="catalogue-item-icon">
                <el-progress
                  v-if="!item.isWanCheng"
                  type="circle"
                  :width="50"
                  :color="item.progress === 100 ? '#5EB35E' : '#e5431f'"
                  :percentage="item.progress || 0"
                  :stroke-width="6"
                ></el-progress>
                <i
                  v-else
                  class="el-icon-success"
                  style="color: #5eb35e; font-size: 35px"
                />
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/components/videoPlayer.vue";
import SvgIcon from "@/components/svgIcon.vue";
import GaudyTitle from "@/components/gaudyTitle.vue";
import {
  getCourseCurriculumEvaluation,
  getCourseGetUserCourseByIDList,
  getStudyProcessEnd,
  getStudyProcessStart,
  getStudyProcessUpdate,
} from "@/api/specializedController";
import { showModel } from "@/utils/tools";
import * as storage from "@/utils/storage";

export default {
  name: "home",
  components: { GaudyTitle, SvgIcon, VideoPlayer },
  data() {
    return {
      playerInfo: {
        courseName: null,
        credits: null,
        unitSpeaker: null,
        PDFurl: [],
      },
      playerList: [],
      playerIndex: 0,
      playerEndIndex: 0,
      courseId: null,
      studyCourseId: null,
      formData: {
        courseContId: undefined,
        star: 5,
        evaluationContent: undefined,
      },
      forTheFirstTime: true,
      editPlayTimer: null,
      currentTime: 0,
      studyProcessId: 0,
    };
  },
  created() {
    const that = this;
    that.courseId = this.$route.query.courseId;
    that.studyCourseId = this.$route.query.studyCourseId;
    getCourseGetUserCourseByIDList({
      trainingPlanId: this.yearId,
      courseId: that.courseId,
      studyCourseId: that.studyCourseId,
    }).then((res) => {
      that.playerInfo = {
        courseName: res.data.courseName,
        credits: res.data.credits,
        unitSpeaker: res.data.unitSpeaker,
        PDFurl: res.data.PDFurl ? JSON.parse(res.data.PDFurl) : [],
      };
      let IsKaoShi = true;
      if (res.data.couresContentList.length > 0) {
        that.playerList = res.data.couresContentList.map((item) => {
          item.url = JSON.parse(item.videoUrl)[0].url;
          item.progress = Number(item.isWanChengRatio);
          if (!(item.isWanCheng && !item.isTongGuo)) {
            IsKaoShi = false;
          }
          return item;
        });
      } else {
        IsKaoShi = false;
      }
      if (IsKaoShi) {
        showModel({
          content: "恭喜您已经完成所有课程学习，请前往考试吧",
          showClose: true,
          showCancelButton: true,
          confirmButtonText: "前往考试",
          closeModal: false,
          confirm: () => {
            console.log("前往考试");
            this.keepAliveNavigation(
              "examination?courseId=" + that.playerList[0].studyCourseId
            );
          },
        });
      } else {
        showModel({
          content: `本课程共有${res.data.couresContentList.length}个章节，须完成所有章节方可参加考试`,
          showClose: true,
          confirmButtonText: "确定",
          closeModal: true,
          confirm: () => {},
        });
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.editPlayTimer);
    this.editPlayTimer = null;
  },
  computed: {
    rateOfLearning() {
      return this.playerList.filter((item) => item.isWanCheng == true).length;
    },
    dragSeek() {
      const item = this.playerList[this.playerIndex];
      return item.isWanCheng;
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  methods: {
    // 附件下载
    handlerFileDown() {
      const a = document.createElement("a");
      a.download = this.playerInfo.PDFurl[0].name;
      a.href = this.playerInfo.PDFurl[0].url;
      a.click();
    },
    // 开始播放
    playerStartInit(args) {
      const that = this;
      if (that.forTheFirstTime) {
        this.forTheFirstTime = false;
        const item = this.playerList[this.playerIndex];
        getStudyProcessStart({
          ppXmWjbzp5b8: item.courseContId,
          c3R1ZHlDb3Vyc2VJZA: item.studyCourseId,
          trainingId: this.yearId,
        }).then((res) => {
          this.studyProcessId = res.data.studyProcessId;
          // 自动转跳到记录播放时间
          if (res.data.playingTime) {
            args.currentTime(res.data.playingTime);
          }
          // 每间隔2分钟记录一次时间
          if (!this.editPlayTimer) {
            this.editPlayTimer = setInterval(() => {
              that.setStudyProcessEdit();
            }, 120000);
          }
        });
      }
    },
    // 记录播放时间请求
    setStudyProcessEdit() {
      getStudyProcessUpdate({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        cgxhewluz1rpbwu: Math.ceil(this.currentTime),
        trainingId: this.yearId,
      }).then(() => {});
    },
    // 视频播放结束请求
    playEnded() {
      getStudyProcessEnd({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        trainingId: this.yearId,
      }).then((res) => {
        this.$set(this.playerList[this.playerEndIndex], "isWanCheng", true);
        this.forTheFirstTime = true;
        this.playerEndIndex = JSON.parse(JSON.stringify(this.playerIndex));
        if (res.data.state === 2) {
          if (this.editPlayTimer) {
            clearInterval(this.editPlayTimer);
            this.editPlayTimer = null;
          }
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.courseId
              );
            },
          });
        }
        this.$refs.videoPlayer.playerEl.play();
      });
    },
    // 点击切换视频
    catalogueClick(data, index) {
      if (this.editPlayTimer) {
        clearInterval(this.editPlayTimer);
        this.editPlayTimer = null;
      }
      this.currentTime = 0;
      this.forTheFirstTime = true;
      this.playerIndex = index;
      this.playerEndIndex = JSON.parse(JSON.stringify(this.playerIndex));
      this.$refs.videoPlayer.playerSwitch(this.playerIndex);
      this.$refs.videoPlayer.playerEl.play();
    },
    // 自动切换视频
    playerSwitch(data) {
      if (this.editPlayTimer) {
        clearInterval(this.editPlayTimer);
        this.editPlayTimer = null;
      }
      this.currentTime = 0;
      this.forTheFirstTime = true;
      this.playerIndex = data.index;
    },
    // 进度条变化
    videoPlayerTimeUpDate(args) {
      if (args.currentTime()) {
        let num = Math.floor((args.currentTime() / args.duration()) * 100);
        if (num >= 100) {
          num = 99;
        }
        this.$set(this.playerList[this.playerIndex], "progress", num);
      }
      this.currentTime = args.currentTime();
    },
    // 提交评价
    submitEvaluation() {
      this.formData.courseContId =
        this.playerList[this.playerIndex].courseContId;
      getCourseCurriculumEvaluation(this.formData).then((res) => {
        if (res.data) {
          this.formData = {
            courseContId: undefined,
            star: 5,
            evaluationContent: undefined,
          };
          this.$message({ message: "评价成功", type: "success" });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.major-player-page {
  padding: 16px 0;
  .back-btn {
    border-radius: 52px;
    padding: 6px 16px;
    background: #b50413;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    span {
      margin-left: 6px;
    }
  }
  .major-player-body {
    margin-top: 16px;
    background-color: #ffffff;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 800px;
    .major-player-left {
      width: calc(100% - 420px);
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      .major-player-header {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 2;
        .major-player-title {
          width: calc(100% - 150px);
          .title-name {
            font-size: 24px;
            color: #21252e;
            margin-bottom: 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .title-content {
            display: flex;
            align-items: center;
            .content-item {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #818496;
              margin-right: 20px;
              .svgIcon {
                margin-right: 5px;
                width: 14px;
                height: 14px;
              }
            }
          }
        }
        .major-player-btn {
          width: 150px;
          text-align: right;
          .file-down {
            cursor: pointer;
            padding: 10px 14px;
            color: #ba2926;
            font-size: 14px;
            background: rgba(186, 41, 38, 0.1);
            box-sizing: border-box;
            border: 1px solid #ba2926;
            border-radius: 8px;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
        }
      }
      .major-player-video {
        margin: 10px 0;
        width: 100%;
        flex: 15;
        background-color: #000000;
      }
      .major-player-bottom {
        display: flex;
        align-items: center;
        flex: 1;
        .major-player-bottom-left {
          width: 180px;
          .bottom-label {
            margin-bottom: 5px;
          }
        }
        .major-player-bottom-content {
          width: calc(100% - 180px);
          ::v-deep .el-input-group__append {
            color: #ffffff;
            background-color: #ba2926;
            border-color: #ba2926;
            cursor: pointer;
          }
        }
      }
    }
    .major-player-right {
      width: 400px;
      display: flex;
      flex-direction: column;
      .major-player-progress {
        flex: 1;
        background: rgba(181, 188, 201, 0.2);
        border-radius: 4px;
        padding: 13px 16px;
        .major-player-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          color: #505363;
          margin-bottom: 5px;
          .major-player-title {
            font-weight: 500;
          }
          .major-player-number {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.7);
            span {
              font-weight: 500;
              color: #ba2926;
            }
          }
        }
        .major-player-progress-body {
          ::v-deep .el-progress {
            .el-progress-bar {
              padding-right: 0;
            }
            i {
              display: none;
            }
          }
        }
      }
      .major-player-catalogue {
        flex: 17;
        height: calc(100% - 116px);
        margin-top: 10px;
        box-sizing: border-box;
        background: rgba(181, 188, 201, 0.2);
        border-radius: 4px;
        .catalogue-title {
          margin-bottom: 14px;
          padding: 13px 16px 0 16px;
          font-size: 16px;
          color: #21252e;
          .number {
            font-size: 12px;
            color: #818496;
            margin-left: 5px;
          }
        }
        .catalogue-body {
          height: calc(100% - 47px);
        }
      }
      .catalogue-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 16px 5px;
        padding: 8px;
        cursor: pointer;
        .catalogue-item-left {
          width: calc(100% - 50px);
          .item-chapter {
            font-size: 12px;
            margin-bottom: 2px;
            color: #818496;
          }
          .item-name {
            font-size: 14px;
            color: #505363;
          }
        }
        .catalogue-item-icon {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep .el-progress__text {
            font-size: 12px !important;
          }
          .suceess {
            width: 20px;
            height: 20px;
          }
        }
        &.active {
          background-color: #ffffff;
          border-radius: 4px;
          .item-chapter {
            color: #1677fe;
          }
          .item-name {
            color: #1677fe;
          }
        }
        &:hover {
          background-color: #ffffff;
          border-radius: 4px;
          .item-chapter {
            color: #1677fe;
          }
          .item-name {
            color: #1677fe;
          }
        }
      }
    }
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
